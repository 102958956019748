body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.bp5-dark .change-register-button.bp5-button:not([class*="bp5-intent-"]) {
  background-color: #dbe9ff26;
}

.bp5-dark .change-register-button.bp5-button:not([class*="bp5-intent-"]):hover {
  background-color: #8f99a826;
}
/*# sourceMappingURL=index.611556fe.css.map */
